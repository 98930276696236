<template>
  <div>
    <div class="my-1">
      <!-- title -->
      <div class="row">
        <div class="col-6">
          <p
            style="font-size: 28px; font-weight: 700"
            class="my-2"
          >
            {{ specification_set.name }}
          </p>
        </div>

        <div class="col-6 text-right">
          <b-button
            variant="outline-primary text-primary"
            @click="edit_modal=true"
          >
            <UilEditAlt /> Edit
          </b-button>
        </div>
        <div class="col-12 py-0 my-0 d-flex">
          <p>Last Updated</p>
          &nbsp;&nbsp;
          <p class="text-muted">
            {{ formatDatetime(specification_set.updated_at) }}
          </p>
        </div>
      </div>
      <!-- published details -->
      <!-- start:: tabs -->
      <b-tabs>
        <!-- start:: items -->
        <b-tab
          active
          title="Spec"
        >
          <div class="row">
            <div class="col-12">
              <div class="card">
                <v-data-table
                  :headers="headers"
                  :items="specification_set.specification_swap_set_items"
                  sort-by="name"
                  class="elevation-1 m-2"
                  :items-per-page="25"
                >
                  <template
                    v-for="(col, i) in filters"
                    v-slot:[`header.${i}`]="{ header }"
                  >
                    <div class="d-flex">
                      <div
                        :key="i"
                        style="display: inline-block; padding: 16px 0"
                      >
                        {{ header.text }}
                      </div>
                      <div
                        id="attachId"
                        :key="'filter' + i"
                        style="float: right; margin-top: 8px"
                      >
                        <v-menu
                          :close-on-content-click="false"
                          :max-width="400"
                          :min-width="300"
                          bottom
                          right
                          attach="#attachId"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon
                                small
                                :style="
                                  activeFilters[header.value] &&
                                    activeFilters[header.value].length <
                                    filters[header.value].length
                                    ? 'color:black'
                                    : 'color:#dedede'
                                "
                              >
                                mdi-filter-variant
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list class="pa-0">
                            <v-list-item-group>
                              <template v-for="item in filters[header.value]">
                                <v-list-item
                                  :key="`${item}`"
                                  :value="item"
                                  :ripple="false"
                                >
                                  <template>
                                    <v-list-item-action>
                                      <b-form-checkbox
                                        v-model="activeFilters[header.value]"
                                        :value="item"
                                      />
                                    </v-list-item-action>
                                    <v-list-item-content>

                                      <v-list-item-title
                                        style="font-weight: normal; text-transform: none"
                                      >{{ getBudgetName(item) }} </v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                          </v-list>
                          <v-row
                            no-gutters
                            style="background-color: #fff"
                          >
                            <v-col cols="6">
                              <b-button
                                block
                                variant="outline-light"
                                style="border-color: transparent; color:  rgba(92, 177, 231, 1) "
                                @click="toggleAll(header.value)"
                              >Select all</b-button>
                            </v-col>
                            <v-col cols="6">
                              <b-button
                                block
                                variant="outline-light"
                                style="border-color: transparent; color:  rgba(92, 177, 231, 1) "
                                @click="clearAll(header.value)"
                              >Deselect all</b-button>
                            </v-col>
                          </v-row>
                        </v-menu>
                      </div>
                    </div>
                  </template>
                  <template v-slot:no-data>
                    There are not spec sets item to show
                  </template>
                  <template v-slot:item.price_per_unit="{ item }">
                    <div>
                      {{
                        Number(item.price_per_unit).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </div>
                  </template>

                  <template v-slot:item.default="{ item }">
                    <div>
                      {{
                        item.budget_group === 'CNST' ? item.construction_work.construction_spec : item.element.element_name
                      }}
                    </div>
                  </template>
                  <template v-slot:item.difference="{ item }">
                    <div>
                      {{
                        item.budget_group === 'CNST' ?

                          Number(item.price_per_unit-item.construction_work.unit_price).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                          :

                          Number((1*item.price_per_unit)-(1*item.element.unit_delivery_w_install)).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })

                      }}
                    </div>
                  </template>
                  <template v-slot:item.budget_group="{ item }">
                    <div>
                      {{
                        getBudgetName(item.budget_group)
                      }}
                    </div>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </b-tab>
        <!-- end:: items -->
      </b-tabs>
      <!-- end:: tabs -->
      <!-- start::edit modal -->
      <b-modal
        v-model="edit_modal"
        :hide-footer="true"
        :no-close-on-backdrop="true"
        title="Update Specification Set"
        size="lg"
        centered
        @close="getCatalogue"
      >
        <EditFormCatalogue
          :spec_set="specification_set"
          @save="updateSpecSet"
        />
      </b-modal>
      <!-- end::editmodal -->
    </div>
  </div>
</template>

<script>
import {
  BTab, BCardText, BTabs, BTable, BButton, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import { UilMessage, UilEditAlt } from '@iconscout/vue-unicons'
import {
  VDataTable,
  VIcon,
  VCol,
  VMenu,
  VBtn,
  VList,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemTitle,
  VDivider,
  VListItemGroup,
  VRow,
  VCheckbox,
} from 'vuetify/lib'
import EditFormCatalogue from './Utils/EditFormCatalogue.vue'
import LogCard from '../ActiveProject/Components/LogCard.vue'

export default {
  components: {
    BFormCheckbox,
    BTab,
    BCardText,
    BTabs,
    LogCard,
    UilMessage,
    BTable,
    VDataTable,
    VIcon,
    VCol,
    VMenu,
    VBtn,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemTitle,
    VDivider,
    VListItemGroup,
    VRow,
    VCheckbox,
    BButton,
    UilEditAlt,
    BModal,
    EditFormCatalogue,
  },
  data() {
    return {
      headers: [
        { text: 'Element', value: 'default' },
        { text: 'Spec', value: 'name' },
        {
          text: 'Budget group',
          value: 'budget_group',
          sortable: false,
          filter: value => (this.activeFilters.budget_group
            ? this.activeFilters.budget_group.includes(value)
            : false),
        },
        { text: 'Manufacturer', value: 'manufacturer' },
        { text: 'Price / Unit w/D&I', value: 'price_per_unit' },
        { text: 'Spec - Base Spec Delta', value: 'difference' },
      ],
      specification_set: {
        specification_swap_set_items: [],
      },
      edit_modal: false,
      // filter variables
      filters: {
        budget_group: [],
      },
      totalRows: 0,
      activeFilters: [],
    }
  },
  mounted() {
    this.getCatalogue()
  },
  methods: {
    getCatalogue() {
      this.$http
        .get(`/specifications/sets/${this.$route.params.id}`)
        .then(response => {
          const { data } = response
          this.specification_set = data
          this.totalRows = this.specification_set.specification_swap_set_items
          let col = ''
          for (col in this.filters) {
            this.filters[col] = this.totalRows// eslint-disable-next-line no-loop-func
              .map(value => value[col])
              .filter((value, index, _arr) => _arr.indexOf(value) === index)
              .sort()
          }
          this.activeFilters = { ...this.filters }
          this.updateBreadcrumb()
        })
        .catch(error => {
          this.show = false
          console.log(error)
          this.showToast('danger', error.response.data.message)
        })
    },
    getBudgetName(name) {
      switch (name) {
        case 'AVIT':
          return 'AV/IT'
        case 'FF&E':
          return 'FF&E'
        default:
          return 'Construction'
      }
    },
    toggleAll(col) {
      this.activeFilters[col] = this.specification_set.specification_swap_set_items
        .map(value => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort()
    },
    clearAll(col) {
      this.activeFilters[col] = []
    },
    updateSpecSet(spec) {
      this.$http
        .put(`/specifications/sets/${this.$route.params.id}`, spec)
        .then(response => {
          this.getCatalogue()
          this.showToast('success', 'Spec updated')
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
      this.edit_modal = false
    },
    formatDatetime(date) {
      return this.$dateF.formatDate(date)
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].text = 'Catalogue'
      this.$route.meta.breadcrumb[0].to = '/catalogue'

      this.$route.meta.breadcrumb[0].active = false
      this.$route.meta.breadcrumb[1].text = `Spec set ${this.specification_set.name}`
      this.$root.$emit('rerender-breadcrumb')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
}
.v-data-table::v-deep td {
  font-size: 14px !important;
  font-weight: 500;
}
.v-data-table::v-deep .v-data-footer__select{
  display: none !important;
}
</style>
<style scoped scoped>
.v-data-table >>> .v-data-table-header {
  background-color: rgba(92, 177, 231, 0.12) !important;
  text-transform: uppercase;
  color: black !important;
  font-size: 50px !important;
}
</style>
<style scoped>
@import "./reset.css";
</style>
